import * as React from "react"
import { useEffect, useState } from 'react';
import { Carousel } from 'primereact/carousel';
import { Button } from 'primereact/button';
import './homePage.scss';
import { responsiveOptions, navigateUrl, URL } from '../../utils/Utils';
import { env } from '../../environment';
import useWindowDimensions from "../../hooks/useWindowDimensions";

const HomePageComponent = (props) => {

  const { t, languageDefault, regions, setRegion } = props;

  const [regionSelected, setRegionSelected] = useState(null);
  
  const navigateToDonate = () => {
    navigateUrl(`/?${URL.REGION}=${regionSelected?.land?.code}`);
  }
  
  const { width, height } = useWindowDimensions();
  useEffect(() => {
    const isMobileScreen = width <= 700;
    if (isMobileScreen && regions && regions.length > 0) {
      regions[0].isSelected = true;
      setRegionSelected(regions[0]);
    }
  }, [width]);

  const selectedRegion = (region) => {
    const result = regions.map(item => {
      return {
        ...item,
        isSelected: region.serial === item.serial
      }
    })
    setRegion(result);
    setRegionSelected(region);
  }

  const productTemplate = (region) => {
    return (
      <div className="land-item-container">
        <div className={`${region.isSelected ? 'land-selected' : ''} land-item`} onClick={() => selectedRegion(region)}>
          <div className="land-image-container">
            <img src={`${env.IMAGE_URL}/${region?.logo}`} alt="Wilderness International Land Logo" />
          </div>
          <div className="item-content">
            <div className="content-container">
              <label>Region:</label>
              <p>{region.name[languageDefault?.code?.toLowerCase()]}</p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="btn-land">
      <div className="btn-land-container">
        <div className="title-land">
          <div className="title" >
            <h2>{t('protecting')}</h2>
          </div>
        </div>
        <div className="carousel-container">
          {
            regions && regions.length > 0 ?
              <Carousel
                value={regions}
                numVisible={2}
                numScroll={2}
                itemTemplate={productTemplate}
                responsiveOptions={responsiveOptions}
              /> :
              <div className="empty">
                {t('regionNF')}
              </div>
          }
        </div>
        <div className="btn-next">
          <Button
            disabled={!regionSelected}
            label={t('protectNow')}
            className="p-button-rounded btn-submit"
            icon="fa-solid fa-chevron-right"
            iconPos="right"
            onClick={navigateToDonate}
          />
        </div>
      </div>
    </div>
  )
}

export default (HomePageComponent);